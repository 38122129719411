// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-icon-spotify {
  width: 44px; /* Adjust size as needed */
  height: auto;
  display: block;
  margin: 3px auto 13px;
}

.spotify-player {
  display: block;
  margin: 0 auto 0;
  max-width: 550px !important;
  min-width: 250px !important;
}

.spotify-code-wrap {
  background-color: #2e7a3c;
  margin-bottom: -10px;
  padding-bottom: 10px;
  border-radius: 12px 12px 0 0;
}

.spotify-code {
  width: 35%; /* Adjust size as needed */
  height: auto;
  margin: 0 auto 0;
  display: block;
}

@media (max-width: 768px) {
  .spotify-code {
    width: 150px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Playlist.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE,0BAA0B;EACvC,YAAY;EACZ,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,oBAAoB;EACpB,4BAA4B;AAC9B;;AAEA;EACE,UAAU,EAAE,0BAA0B;EACtC,YAAY;EACZ,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".details-icon-spotify {\n  width: 44px; /* Adjust size as needed */\n  height: auto;\n  display: block;\n  margin: 3px auto 13px;\n}\n\n.spotify-player {\n  display: block;\n  margin: 0 auto 0;\n  max-width: 550px !important;\n  min-width: 250px !important;\n}\n\n.spotify-code-wrap {\n  background-color: #2e7a3c;\n  margin-bottom: -10px;\n  padding-bottom: 10px;\n  border-radius: 12px 12px 0 0;\n}\n\n.spotify-code {\n  width: 35%; /* Adjust size as needed */\n  height: auto;\n  margin: 0 auto 0;\n  display: block;\n}\n\n@media (max-width: 768px) {\n  .spotify-code {\n    width: 150px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
