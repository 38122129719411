// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-qr {
  width: 300px; /* Adjust size as needed */
  height: auto;
  margin: 0 auto 10px;
  margin-top: 20px;
  display: block;
}

@media (max-width: 768px) {
  .details-qr {
    width: 260px;
  }
}

.ln-invoice {
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/Gifts.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE,0BAA0B;EACxC,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".details-qr {\n  width: 300px; /* Adjust size as needed */\n  height: auto;\n  margin: 0 auto 10px;\n  margin-top: 20px;\n  display: block;\n}\n\n@media (max-width: 768px) {\n  .details-qr {\n    width: 260px;\n  }\n}\n\n.ln-invoice {\n  cursor: pointer;\n  margin-bottom: 0px;\n  margin-top: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
