// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RSVP.css */
.rsvp-container {
  height: 100vh !important;     /* Adjust the height based on your layout */
}
  `, "",{"version":3,"sources":["webpack://./src/components/RSVP.css"],"names":[],"mappings":"AAAA,aAAa;AACb;EACE,wBAAwB,MAAM,2CAA2C;AAC3E","sourcesContent":["/* RSVP.css */\n.rsvp-container {\n  height: 100vh !important;     /* Adjust the height based on your layout */\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
