// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Contacts.css */
.iframe-map {
  display: block;
  width: 600px;
  height: 400px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .iframe-map {
    width: 80vw;
    height: 60vw;
  }
}
  `, "",{"version":3,"sources":["webpack://./src/components/Contacts.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,cAAc;EACd,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":["/* Contacts.css */\n.iframe-map {\n  display: block;\n  width: 600px;\n  height: 400px;\n  object-fit: cover;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n@media (max-width: 768px) {\n  .iframe-map {\n    width: 80vw;\n    height: 60vw;\n  }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
