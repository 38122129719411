// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inviteImage {
  display: block;
  width: 30vw;
  height: 30vw;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .inviteImage {
    width: 90vw;
    height: 90vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Invite.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".inviteImage {\n  display: block;\n  width: 30vw;\n  height: 30vw;\n  object-fit: cover;\n  margin-left: auto;\n  margin-right: auto;\n  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.3);\n}\n\n@media (max-width: 768px) {\n  .inviteImage {\n    width: 90vw;\n    height: 90vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
